import React from "react"
import loadable from "@loadable/component"
import Layout from "../components/layout"

import PropertyDetailImg from "../images/property_detail_img.png"
import PropertyImg1 from "../images/featured_properties_img_1.png"
import PropertyImg2 from "../images/featured_properties_img_2.png"
import PropertyImg3 from "../images/featured_properties_img_3.png"
import PropertyNegoImg from "../images/nego_details.png"

const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"))
const NewHomesBanner = loadable(() => import("../components/NewHomesBanner/NewHomesBanner"))
const NewHomesDesc = loadable(() => import("../components/NewHomesDesc/NewHomesDesc"))
const NewHomesAvailability = loadable(() => import("../components/NewHomesAvailability/NewHomesAvailability"))
const PropertyDetailsMap = loadable(() => import("../components/PropertyDetailsMap/PropertyDetailsMap"))
const NewHomesProperties = loadable(() => import("../components/SimilarProperties/NewHomesProperties"))
const NewsLetterModule = loadable(() => import("../components/NewsLetterModule/NewsLetterModule"))

const NewHomeDetails = () => {

    const allProperty = [
        {
            price: 'Price from £325,000 – £650,000',
            title: '2-4 bedroom houses for sale',
            displayaddress: 'St Leonards Square, Thrybergh, S65',
            image: PropertyImg1
        },
        {
            price: 'Price from £325,000 – £650,000',
            title: '2-4 bedroom houses for sale',
            displayaddress: 'St Leonards Square, Thrybergh, S65',
            image: PropertyImg2
        },
        {
            price: 'Price from £325,000 – £650,000',
            title: '2-4 bedroom houses for sale',
            displayaddress: 'St Leonards Square, Thrybergh, S65',
            image: PropertyImg3
        }
    ]

    return (
        <Layout>
            <div className="layout-padding-top"></div>

            <BreadcrumbModule
                title='St Leonards Square, Thrybergh S65'
                subparentlink="/"
                subparentlabel="New Homes"
                parentlink="/"
                parentlabel="Services"
            />

            <NewHomesBanner bannerImg={PropertyDetailImg} />

            <div className='layout-grey-bg-theme'>
                <NewHomesDesc
                    negoImg={PropertyNegoImg}
                />

                <NewHomesAvailability />

                <PropertyDetailsMap
                    lat={51.528607}
                    lng={-0.4312419}
                />

                <NewHomesProperties propertyDetail={allProperty} heading="Other Developments" />

                <NewsLetterModule />
            </div>
        </Layout>
    )
}

export default NewHomeDetails